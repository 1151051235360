<template>
    <div class="v-select">
        <div class="v-select__container" @click="selectClicked" :class="{ 'list-dropped': isOptionListVisible }">
            <p class="v-select__label" v-if="showLabel">{{ selectName }}</p>
            <p class="v-select__name"> {{ selected }}</p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 11L12 16L17 11" stroke="#424F60" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>
        <Transition name="fade">
            <div v-show="isOptionListVisible" class="v-select__options" :class="{ 'scroll': scroll }">
                <div class="v-select__radio-container" v-for="   option, number in options   " :key="option.value">
                    <input type="radio" :id="selectName + selectId + number" :name="selectName + selectId"
                        :value="option.name" ref="inputs">
                    <label :for="selectName + selectId + number" @click.stop="selectOption(option)">{{ option.name
                        }}</label>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    name: 'v-select',
    props: {
        selectName: {
            type: String,
            default() {
                return 'value'
            }
        },
        selectId: {
            type: Number,
            default() {
                return 0
            }
        },
        options: {
            type: Array,
            default() {
                return [
                ]
            }
        },
        selected: {
            type: String,
            default() {
                return 'Select'
            }
        },
        showLabel: {
            type: Boolean,
            default() {
                return true
            }
        },
        scroll: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    emits: ['select'],
    data() {
        return {
            isOptionListVisible: false,
        }
    },

    created() {
        window.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)) {
                this.isOptionListVisible = false
            }
        })
    },

    beforeUnmount() {
        window.removeEventListener("click", (e) => {
            if (!this.$el.contains(e.target)) {
                this.isOptionListVisible = false
            }
        });
    },

    methods: {
        selectClicked() {
            this.isOptionListVisible = !this.isOptionListVisible
        },
        selectOption(option) {
            this.$emit('select', option);
            this.hideSelect();
        },
        hideSelect() {
            this.isOptionListVisible = false;
        },
        clearSelect() {
            this.$refs.inputs.forEach((input) => {
                input.checked = false
            })
        }

    }

}
</script>

<style lang="scss">
.v-select {
    position: relative;

    width: 100%;

    &__container {
        width: 100%;

        position: relative;

        cursor: pointer;

        svg {
            position: absolute;
            bottom: 14px;
            right: 15px;

            transition: transform .3s
        }

        &.list-dropped {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    &__label {
        @extend %text-regular;
        color: var(--bw1);
    }

    &__name {
        @extend %text-regular;
        color: var(--bw0);

        background-color: var(--bw4);
        border: 1px solid var(--bw4);
        border-radius: 6px;

        padding: 12px 44px 12px 16px;

        margin-top: 8px;
    }

    &__options {
        position: absolute;
        width: 100%;

        display: flex;
        flex-direction: column;

        border: 1px solid var(--bw3);

        right: 0;
        top: calc(100% + 8px);
        background: var(--bw6);
        border-radius: 6px;

        z-index: 5;

        &.scroll {
            max-height: 295px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background: $bw6;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $bw3;
                border-radius: 10px;
                border: none;
            }
        }
    }

    &__radio-container {
        position: relative;
        background-color: transparent;

        &:first-of-type {
            border-radius: 6px 6px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 6px 6px;
        }

        input {
            position: absolute;
            z-index: -99;
            width: 1px;
            height: 1px;
            opacity: 0;
        }

        label {
            @extend %text-regular;
            color: var(--bw0);

            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 12px 16px;

            position: relative;
            z-index: 2;

            background-color: white;

            cursor: pointer;

            border-bottom: 1px solid var(--bw3);

            transition: background-color .3s;

            &:hover {
                background-color: var(--bw3);
            }
        }

        &:first-of-type {
            label {
                border-radius: 6px 6px 0 0;
            }
        }

        &:last-of-type {
            label {
                border-radius: 0 0 6px 6px;

                border-bottom: none;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-to {
    opacity: 1;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>