<template>
    <tr class="order-specification-row">
        <td class="number">{{ number }}</td>
        <td class="name">{{ name }}</td>
        <td class="articul">{{ articul }}</td>
        <td class="action">{{ action }}</td>
        <td class="content">{{ content }}</td>
        <td class="amount">{{ amount }}</td>
        <td class="unit">{{ unit }}</td>
    </tr>
</template>

<script>
export default {
    name: 'OrderSpecificationRow',

    props: {
        number: {
            type: Number,
            default() {
                return 0
            }
        },
        name: {
            type: String,
            default() {
                return "Номенклатура"
            }
        },
        articul: {
            type: String,
            default() {
                return 'Артикул'
            }
        },
        action: {
            type: String,
            default() {
                return 'Действие'
            }
        },
        content: {
            type: String,
            default() {
                return 'Содержание'
            }
        },
        amount: {
            type: Number,
            default() {
                return 0
            }
        },
        unit: {
            type: String,
            default() {
                return 'шт'
            }
        }
    },
}
</script>

<style lang="scss">
.order-specification-row {
    td {
        @extend %text-regular;
        color: var(--bw0);
        padding: 12px 0 12px 27px;

        &.name {
            max-width: 300px;
            padding-right: 10px;
        }

        &.number {
            padding: 12px 0 12px 32px;
        }

        &.amount {
            text-align: center;
            padding: 12px;
        }

        &.content {
            color: $bw3;
        }

        &.unit {
            text-align: right;
            padding-right: 34px;
        }
    }
}

@media screen and (max-width: 1600px) {
    .order-specification-row {
        td {
            padding: 12px 0 12px 18px;

            &.name {
                max-width: 300px;
                padding-right: 10px;
            }

            &.number {
                padding: 12px 0 12px 32px;
            }

            &.amount {
                text-align: center;
                padding: 12px;
            }

            &.content {
                color: $bw3;
            }

            &.unit {
                text-align: right;
                padding-right: 34px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .order-specification-row {
        td {
            padding: 12px 0 12px 27px;

            &.name {
                max-width: 300px;
                padding-right: 10px;
            }

            &.number {
                padding: 12px 0 12px 32px;
            }

            &.amount {
                text-align: center;
                padding: 12px;
            }

            &.content {
                color: $bw3;
            }

            &.unit {
                text-align: right;
                padding-right: 34px;
            }
        }
    }
}
</style>