<template>
  <app-header></app-header>
  <div class="wrapper">
    <div class="login-page">
      <div class="login-page__content">
        <div class="logo">
          <a href="#" class="logo__icon">
            <img v-if="false" src="@/assets/icons/logo.svg" alt="logo" />
            <p v-else class="logo__title">LOGO</p>
          </a>
          <p class="logo__text">удобный инструмент для бизнеса</p>
        </div>
        <div class="login-page__container" :class="{ loading: isLoading }">
          <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
          <transition name="component-fade" mode="out-in">
            <component
              v-bind:is="activePage"
              :action="currentAction"
              :email="currentEmail"
              :token="currentToken"
              @changeBlock="changeBlock"
              @email-change="onEmailChange"
              @action-change="onActionChange"
              @token-change="onTokenChange"
              @inn-changed="onInnChanged"
              @regStatusChange="onRegStatusChange"
              @name-changed="onNameChanged"
              @set-loading="onSetLoading"
              @token-time-change="onTokenTimeChange"
            >
            </component>
          </transition>
        </div>
      </div>
      <img class="login-page__img" src="@/assets/images/login-img.webp" alt="tools" />
    </div>
  </div>
</template>

<script>
import LoginBlock from '@/components/login/LoginBlock.vue'
import RegistrationBlock from '@/components/login/RegistrationBlock.vue'
import RecoveryBlock from '@/components/login/RecoveryBlock.vue'
import PincodeBlock from '@/components/login/PincodeBlock.vue'
import AppHeader from '@/components/AppHeader.vue'
import PasswordBlock from '@/components/login/PasswordBlock.vue'
import vLoading from '../components/v-loading.vue'

import { mapWritableState } from 'pinia'
import { useUserToken } from '@/stores/userToken'

export default {
  name: 'LoginView',
  components: {
    LoginBlock,
    AppHeader,
    RegistrationBlock,
    RecoveryBlock,
    PincodeBlock,
    PasswordBlock,
    vLoading
  },

  emits: ['userLogged', 'userLogout'],

  data() {
    return {
      activePage: 'loginBlock',
      currentEmail: '',
      currentAction: '',
      currentToken: '',
      isLoading: false
    }
  },

  mounted() {
    this.userLogged = false
  },

  computed: {
    ...mapWritableState(useUserToken, { userToken: 'token' }),
    ...mapWritableState(useUserToken, { userLiveTime: 'tokenLiveTime' }),
    ...mapWritableState(useUserToken, { storeCompanyInn: 'companyInn' }),
    ...mapWritableState(useUserToken, { regStatus: 'regStatus' }),
    ...mapWritableState(useUserToken, { storeCompanyName: 'companyName' }),
    ...mapWritableState(useUserToken, { userLogged: 'logged' }),
    ...mapWritableState(useUserToken, { userEmail: 'userEmail' })
  },

  methods: {
    changeBlock(block) {
      this.activePage = block
    },

    onEmailChange(email) {
      this.currentEmail = email
      this.userEmail = email
    },

    onActionChange(action) {
      this.currentAction = action
    },

    onTokenChange(token) {
      this.userToken = token
      this.currentToken = token
      this.userLogged = true
    },

    onTokenTimeChange(time) {
      this.userLiveTime = time
    },

    onRegStatusChange(status) {
      this.regStatus = status
    },

    onNameChanged(name) {
      this.storeCompanyName = name
    },

    onInnChanged(inn) {
      this.storeCompanyInn = inn
    },

    onSetLoading(status) {
      this.isLoading = status
    }
  }
}
</script>

<style lang="scss">
.login-page {
  display: flex;
  max-height: 850px;

  &__content {
    padding: 40px;

    border-radius: 6px 0 0 6px;
    background: var(--bw6);

    width: 100%;
  }

  &__container {
    max-width: 360px;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 150px;

    position: relative;

    &.loading {
      pointer-events: none;
    }
  }

  &__img {
    width: 100%;
    max-width: 1000px;
    height: auto;
    object-fit: cover;
    border-radius: 0 6px 6px 0;
  }
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter,
.component-fade-leave-to,
.component-fade-leave-active {
  opacity: 0;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media screen and (max-width: 1600px) {
  .login-page {
    display: flex;
    max-height: 100%;

    &__img {
      max-width: 760px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .login-page {
    &__content {
      padding: 10px 0;

      border-radius: 0;
      background: var(--bw6);

      width: 100%;
    }

    &__logo {
      width: 193px !important;
      height: 52px;
      flex-shrink: 0;
    }

    .logo {
      height: 52px;

      &__icon {
        width: 193px !important;
        height: 52px;
      }

      &__title{
        
      }
    }

    .logo {
      &__text {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
    }

    &__container {
      max-width: 360px;

      margin: 0 auto;

      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 100px;
      padding-bottom: 100px;
    }

    &__img {
      display: none;
    }
  }
}
</style>
