<template>
  <div class="analitics-page">
    <custom-header class="title" :title="'аналитика'"></custom-header>
    <div class="analitics-page__grid">
      <section class="general-statistic border-block" :class="{ 'loading': isGeneralLoading }">
        <v-loading :is-loading="isGeneralLoading" :color="'white'"></v-loading>
        <div class="general-statistic__filters">
          <form ref="periodForm">
            <v-switch :options="periodSwitchOptions" @switched="onSwitched"></v-switch>
          </form>
          <v-calendar :is-range="true" @date-change="onDateChange" @date-clear="onDateClear">
            <div class="date-pick" :class="{ 'active': date != null }">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 8H20" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 3V5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M8 3V5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V6Z"
                  stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 16.5H16.002V16.502H16V16.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M12 16.5H12.002V16.502H12V16.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M8 16.5H8.002V16.502H8V16.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M16 12.5H16.002V12.502H16V12.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M12 12.5H12.002V12.502H12V12.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M8 12.5H8.002V12.502H8V12.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span v-if="showedDate == null">Выбрать период</span>
              <span v-else>{{ showedDate }}</span>
            </div>
          </v-calendar>
        </div>
        <p class="general-statistic__orders">{{ generalStat.orders }} заказов</p>
        <p class="general-statistic__text">За весь период времени вы сделали {{ generalStat.orders }} заказов, приняли
          {{ generalStat.shipments }} отгрузок, оформили
          {{ generalStat.goods }} единиц товара и потратили {{ generalStat.sum }} рублей</p>
      </section>
      <section class="results-block"
        :class="{ 'loading': isResultLoading }, { 'not-empty': getQuarterStatistics != null }">
        <v-loading :is-loading="isResultLoading" :color="'white'"></v-loading>
        <div class="results-block__header">
          <h3>Итоги {{ getQuarterLabel }} квартала года</h3>
          <div class="scale">
            <span v-for="number in 4" :class="{ 'active': number == activeQuarter }"
              @click="selectQuarter(number)"></span>
          </div>
        </div>
        <div class="results-block__container">
          <template v-if="getQuarterStatistics != null">
            <section class="result-item border-block">
              <p class="result-item__title">Объём реализованной продукции</p>
              <p class="result-item__value">{{ getQuarterStatistics.product_volume_title }}</p>
              <p class="result-item__text">{{ getQuarterStatistics.product_volume }}</p>
            </section>
            <section class="result-item border-block">
              <p class="result-item__title">Объём заказов</p>
              <p class="result-item__value">{{ getQuarterStatistics.order_volume_title }}</p>
              <p class="result-item__text">{{ getQuarterStatistics.order_volume }}</p>
            </section>
            <section class="result-item border-block">
              <p class="result-item__title">Кол-во единиц товара</p>
              <p class="result-item__value">{{ getQuarterStatistics.product_quantity_title }}</p>
              <p class="result-item__text">{{ getQuarterStatistics.product_quantity }}</p>
            </section>
          </template>
          <div class="border-block placeholder" v-else>
            <p class="placeholder__header">Итоги отсутствуют</p>
            <p class="placeholder__text">Итоги будут доступны после завершения квартала</p>
          </div>
        </div>
      </section>
      <section class="news" :class="{ 'loading': isHistoryLoading }">
        <v-loading :is-loading="isHistoryLoading" :color="'white'"></v-loading>
        <template v-if="reports.length > 0">
          <div v-for="report in reports" class="news-item">
            <img class="news-item__img" src="@/assets/images/news.jpg" alt="фото новости">
            <div class="news-item__content">
              <div>
                <h5 class="news-item__title">{{ report.title }}</h5>
                <p class="news-item__text" v-html="report.text"></p>
              </div>
              <router-link class="news-item__link" :to="{ name: 'meeting', params: { id: report.id } }">
                <span>Читать дальше</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 12H21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M16 7L21 12L16 17" stroke="black" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </router-link>
            </div>
          </div>
          <v-pagination class="catalog-page__pagination" v-show="isPagination" :totalPages="getTotalPages"
            :currentPage="currentPage" @page-change="pageChange"></v-pagination>
        </template>
        <div v-else class="news__placeholder border-block">
          <p class="news__placeholder__header">Данные за текущие период недоступны</p>
          <p class="news__placeholder__text">Отчеты о встречах будут доступны после завершения квартала</p>
        </div>
      </section>
      <section class="frequently-orders border-block" :class="{ 'loading': isGoodsLoading }">
        <v-loading :is-loading="isGoodsLoading" :color="'white'"></v-loading>
        <h3>Самые часто заказываемые товары</h3>
        <div class="frequently-orders__container">
          <template v-if="goods.length > 0">
            <section v-for="good in goods" class="frequent-item">
              <img v-if="good.images[0] != null && good.images[0] != undefined && good.images[0] != ''"
                class="frequent-item__img" :src="good.images[0]" alt="изображение товара">
              <img v-else class="frequent-item__img img-placeholder" src="@/assets/icons/img-placeholder.svg"
                alt="изображение товара">
              <div class="frequent-item__content">
                <p class="frequent-item__name">{{ good.name }}</p>
                <div class="frequent-item__bottom">
                  <p class="frequent-item__price">{{ good.ordered_count }} шт.</p>
                  <router-link class="frequent-item__link" :to="{ name: 'product', params: { id: good.id } }">
                    <span>Перейти к товару</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3 12H21" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M16 7L21 12L16 17" stroke="white" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </router-link>
                </div>
              </div>
            </section>
          </template>
          <p v-else class="frequently-orders__text">Товаров не найдено</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue';
import AlertBlock from '../components/AlertBlock.vue';
import vSwitch from '../components/v-switch.vue';
import PaymentRow from '../components/PaymentRow.vue';
import vCalendar from '../components/v-calendar.vue';
import vLoading from '../components/v-loading.vue';
import vPagination from '../components/v-pagination.vue';

import { mapWritableState } from 'pinia'
import { useBreadcrumbs } from '../stores/breadcrumbs';

import { serverRequest } from '../api/request';

export default {
  name: 'AnaliticsView',
  components: {
    CustomHeader,
    AlertBlock,
    vSwitch,
    PaymentRow,
    vCalendar,
    vLoading,
    vPagination
  },

  data() {
    return {
      activePeriod: 0,
      activeQuarter: 1,
      date: null,
      showedDate: null,

      isGeneralLoading: false,
      isHistoryLoading: false,
      isResultLoading: false,
      isGoodsLoading: false,

      currentPage: 1,
      itemsOnPage: 10,
      allItemsCount: 0,

      generalStat: {
        orders: 0,
        shipments: 0,
        goods: 0,
        sum: 0
      },

      reports: [],
      goods: [],
      quarters: [
        // {
        //   number: 1,
        //   statistics: [
        //     {
        //       name: 'Первый квартал',
        //       title: 'Итоги',
        //       text: 'Итоггг'
        //     },
        //     {
        //       name: 'Первый квартал',
        //       title: 'Итоги',
        //       text: 'Итоггг'
        //     },
        //   ]
        // },
        // {
        //   number: 3,
        //   statistics: [
        //     {
        //       name: 'Третий квартал',
        //       title: 'Итоги',
        //       text: 'Итоггг'
        //     },
        //     {
        //       name: 'Третий квартал',
        //       title: 'Итоги',
        //       text: 'Итоггг'
        //     },
        //   ]
        // }
      ],

      periodSwitchOptions: [
        {
          id: 'd1',
          name: 'date-type',
          value: '0',
          label: 'Все время',
          checked: true
        },
        {
          id: 'd2',
          name: 'date-type',
          value: '1',
          label: 'Год',
          checked: false
        },
        {
          id: 'd3',
          name: 'date-type',
          value: '2',
          label: 'Месяц',
          checked: false
        },
      ],
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'analitics',
        name: 'Анатилика'
      },
    ]
    this.getGeneralStat()
    this.getReports()
    this.getQuarterResults()
    this.getGoods()
  },

  computed: {
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),

    isPagination() {
      if (this.allItemsCount > this.itemsOnPage) {
        return true
      }
      else {
        return false
      }
    },

    getQuarterLabel() {
      switch (this.activeQuarter) {
        case 1:
          return 'первого'
        case 2:
          return 'второго'
        case 3:
          return 'третьего'
        case 4:
          return 'четвёртого'
        default:
          return 'первого'
      }
    },

    getTotalPages() {
      if (this.isPagination) {
        return Math.ceil(this.allItemsCount / this.itemsOnPage)
      }
      else {
        return 1
      }
    },

    getQuarterStatistics() {
      let quarter = this.quarters.find((item) => item.quarter == this.activeQuarter)
      if (quarter != undefined)
        return quarter
      else {
        return null
      }
    },

    currentPosition() {
      return (this.currentPage - 1) * this.itemsOnPage
    },
  },

  methods: {
    pageChange(page) {
      this.currentPage = page
      this.getReports()
    },

    onDateChange(date) {
      this.periodSwitchOptions.forEach((item) => {
        item.checked = false
      })

      this.$refs.periodForm.reset()

      this.date = date

      let start_date = (this.date[0].getDate()).toString().padStart(2, '0') + '.' + (parseInt(this.date[0].getMonth() + 1)).toString().padStart(2, '0') + '.' + this.date[0].getFullYear();
      let end_date = (this.date[1].getDate()).toString().padStart(2, '0') + '.' + (parseInt(this.date[1].getMonth() + 1)).toString().padStart(2, '0') + '.' + this.date[1].getFullYear();

      this.showedDate = start_date + ' - ' + end_date
      this.activePeriod = 3;

      this.getGeneralStat()
    },

    onDateClear() {
      this.date = null
      this.showedDate = null

      this.periodSwitchOptions[0].checked = true
      this.activePeriod = this.periodSwitchOptions[0].value

      this.getGeneralStat()
    },

    selectQuarter(number) {
      this.activeQuarter = number
    },

    onSwitched(value) {
      this.date = null
      this.showedDate = null
      this.activePeriod = value

      this.getGeneralStat()
    },

    async getGeneralStat() {
      this.isGeneralLoading = true

      let formData = {
        period: this.activePeriod,
        start_date: null,
        end_date: null
      }

      if (this.date != null) {
        formData.start_date = this.date[0].getDate() + '.' + (parseInt(this.date[0].getMonth() + 1)) + '.' + this.date[0].getFullYear();
        formData.end_date = this.date[1].getDate() + '.' + (parseInt(this.date[1].getMonth() + 1)) + '.' + this.date[1].getFullYear();
      }

      console.log(formData);

      let result = await serverRequest('POST', 'json', 'analytics/get_statistics', formData)

      if (result != 'error' && result != 'unathorized') {
        this.generalStat.orders = result.orders
        this.generalStat.goods = result.goods
        this.generalStat.shipments = result.shipments
        this.generalStat.sum = result.sum
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isGeneralLoading = false
    },

    async getReports() {
      this.isHistoryLoading = true

      let formData = {
        amount: this.itemsOnPage,
        start_pos: this.currentPosition
      }

      console.log(formData);

      let result = await serverRequest('POST', 'json', 'analytics/get_meeting_results', formData)

      if (result != 'error' && result != 'unathorized') {
        this.allItemsCount = result.all_meeting_count
        this.reports = result.meetings
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isHistoryLoading = false
    },

    async getQuarterResults() {
      this.isResultLoading = true

      let result = await serverRequest('GET', 'json', 'analytics/get_quarters_results', null)

      if (result != 'error' && result != 'unathorized') {
        this.quarters = result.quarters
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isResultLoading = false
    },

    async getGoods() {
      let formData = {
        type: 'top_ordered',
        amount: 4
      }

      let result = await serverRequest('POST', 'json', 'get_goods', formData)

      if (result != 'error' && result != 'unathorized') {
        this.goods = result.goods
      }
      else {
        //alert('Ошибка запроса')
      }
    },
  }
}
</script>

<style lang="scss">
.analitics-page {
  @extend %white-bg-block;

  width: 100%;

  .title {
    width: fit-content;

    &::after {
      width: 100%;
    }
  }

  &__grid {
    margin-top: 32px;

    display: grid;
    grid-template-columns: minmax(500px, 666px) minmax(500px, 750px);
    column-gap: 32px;
    row-gap: 24px;

    grid-auto-flow: dense;
  }

  .border-block {
    padding: 24px;

    border: 1px solid $bw3;
    border-radius: 6px;
  }

  .general-statistic {
    position: relative;

    &.loading {
      pointer-events: none;
    }

    .v-switch {
      padding: 0;

      label {
        @extend %text-medium;

        padding: 8px 12px;

        border-radius: 4px;
      }

      input:checked+label {
        color: var(--primary);
        background-color: var(--primary-bg);
      }
    }

    .date-pick {
      cursor: pointer;

      display: flex;
      align-items: center;
      column-gap: 12px;

      width: fit-content;

      padding: 8px 12px;

      background-color: transparent;

      border-radius: 4px;

      transition: background-color .3s;

      &.active {
        background-color: var(--primary-bg);

        span {
          color: $primary;
        }

        svg {
          path {
            stroke: $primary;
          }
        }
      }

      span {
        @extend %text-medium;
        color: var(--bw0);
        white-space: nowrap;
      }

      &:hover {
        background-color: var(--primary-bg);
      }
    }

    &__filters {
      display: flex;
      column-gap: 24px;
    }

    &__orders {
      margin-top: 32px;

      @extend %DB;
      color: $bw0;
    }

    &__text {
      margin-top: 10px;

      @extend %text-regular;
      color: $bw0;
    }
  }

  .news {
    grid-row: span 4;

    display: flex;
    flex-direction: column;
    row-gap: 12px;

    position: relative;

    &.loading {
      pointer-events: none;
    }

    &__placeholder {
      padding-top: 54px;
      padding-bottom: 54px;

      &__header {
        @extend %text-medium;
        color: $bw0;
        text-align: center;
      }

      &__text {
        margin-top: 4px;

        @extend %text-regular;
        color: $bw0;
        text-align: center;
      }
    }

    .news-item {
      padding: 16px;

      border: 1px solid $bw3;
      border-radius: 6px;

      display: flex;
      column-gap: 12px;

      &__img {
        width: 128px;
        height: 128px;

        object-fit: cover;

        border-radius: 4px;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;
      }

      &__title {
        @extend %text-medium;
        color: $bw0;
      }

      &__text {
        margin-top: 8px;
        @extend %text-small;
        color: $bw0;

        text-align: justify;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
      }

      &__link {
        margin-top: auto;

        padding: 6px 12px;

        width: 100%;

        background-color: $bw4;

        border-radius: 6px;

        display: flex;
        column-gap: 4px;
        justify-content: center;
        align-items: center;

        justify-self: flex-end;

        span {
          @extend %text-regular;
          color: $bw0;
        }
      }
    }
  }

  .results-block {
    position: relative;

    &.not-empty {
      grid-row: span 2;
    }

    &.loading {
      pointer-events: none;
    }


    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 24px;

      h3 {
        @extend %H2;
        font-size: 20px;
        line-height: 24px;
        color: $bw0;
        white-space: nowrap;
      }

      .scale {
        margin-top: 2px;

        display: flex;
        align-items: center;
        column-gap: 8px;

        width: 100%;
        max-width: 400px;

        span {
          content: "";

          width: 100%;
          height: 6px;

          border-radius: 40px;
          background-color: var(--blue-bg);

          transition: background-color .3s;

          cursor: pointer;

          &.active {
            background-color: var(--blue);
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }

    &__container {
      margin-top: 20px;

      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    .result-item {
      &__title {
        @extend %text-regular;
        color: $bw6;

        padding: 8px 16px;

        border-radius: 8px;
        background-color: var(--green-dark);

        width: fit-content;
      }

      &__value {
        margin-top: 16px;

        @extend %DB;
        color: $bw0;
      }

      &__text {
        margin-top: 4px;

        @extend %text-regular;
        color: $bw0;
      }
    }

    .placeholder {
      min-height: 175px;

      &__text {
        margin-top: 8px;

        @extend %text-regular;

        color: $bw0;
      }

      &__header {
        @extend %DB;
        color: $bw0;
      }


    }
  }

  .frequently-orders {
    position: relative;

    &.loading {
      pointer-events: none;
    }

    &__text {
      @extend %text-medium;
    }

    h3 {
      @extend %text-regular;
      color: $bw1;
    }

    &__container {
      margin-top: 16px;

      display: flex;
      flex-direction: column;
      row-gap: 12px;
    }

    .frequent-item {
      display: flex;
      column-gap: 13px;

      padding-bottom: 12px;
      border-bottom: 1px solid $bw3;

      &__img {
        flex-shrink: 0;

        width: 96px;
        height: 96px;

        border-radius: 4px;

        object-fit: contain;

        &.img-placeholder {
          background-color: $bw4;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
      }

      &__name {
        @extend %text-regular;
        color: $bw0;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
      }

      &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__price {
        @extend %text-medium;
        color: $bw0;

        font-size: 20px;
        line-height: 24px;
      }

      &__link {
        @extend %link
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .analitics-page {
    &__grid {
      row-gap: 24px;
      column-gap: 16px;
    }

    .results-block {
      &__header {
        flex-direction: column;
        row-gap: 24px;
        align-items: flex-start;

        h3 {
          font-size: 24px;
          line-height: 28px;
          font-weight: 600;
        }

        .scale {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .analitics-page {
    &__grid {
      row-gap: 32px;
      column-gap: 16px;
      grid-template-columns: 1fr 1fr;
    }

    .general-statistic {
      grid-column: span 2;
    }

    .results-block {
      grid-column: span 2;

      &__header {
        flex-direction: column;
        row-gap: 24px;
        align-items: flex-start;

        h3 {
          font-size: 24px;
          line-height: 28px;
          font-weight: 600;
        }

        .scale {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .analitics-page {
    &__grid {
      row-gap: 24px;
      column-gap: 16px;
      grid-template-columns: 1fr;
    }

    .general-statistic {
      grid-column: span 1;
    }

    .results-block {
      grid-column: span 1;

      margin-top: 16px;
    }
  }
}

@media screen and (max-width: 600px) {
  .analitics-page {
    .general-statistic {
      &__filters {
        flex-direction: column;
        row-gap: 16px;

        .v-calendar {
          width: 100%;
        }

        .date-pick {
          width: 100%;
        }
      }
    }

    .results-block {
      &__header {
        h3 {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    .news {
      .news-item {
        &__text {
          display: none;
        }
      }
    }
  }
}
</style>