<template>
    <section class="login-block pincode-block">
        <h1>{{ text }}</h1>
        <div class="input-wrapper">
            <v-otp-input ref="otpInput" class="pincode-wrapper" :class="{ 'complete': isComplete }, { 'error': isError }"
                v-model:value="code" input-classes="pincode-input" separator="" :placeholder="['*', '*', '*', '*']"
                :num-inputs="4" :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange"
                @on-complete="handleOnComplete" @keypress.enter="onEnter" />
        </div>
        <p class="text">Не пришел код? <button class="registration" @click="newCodeRequest">Запросите
                повторно</button> или проверьте корректность указанной почты</p>
        <button class="button" @click="submitForm">Подтвердить</button>
        <button class="button back-btn" @click="$emit('changeBlock', 'loginBlock')">Назад</button>
    </section>
</template>

<script>
// import VuePincodeInput from 'vue3-pincode-input';
import VOtpInput from 'vue3-otp-input';
// import PincodeInput from 'vue-pincode-input';

export default {
    name: 'RecoveryBlock',

    components: {
        VOtpInput
    },

    props: {
        action: {
            type: String,
            default() {
                return ''
            }
        },
        email: {
            type: String,
            default() {
                return ''
            }
        },
        action: {
            type: String,
            default() {
                return ''
            }
        },
    },

    emits: ['changeBlock', 'tokenChange', 'setLoading', 'tokenTimeChange'],

    data() {
        return {
            code: '',
            isComplete: false,
            isError: false,
            text: 'Вам на почту выслан код подтверждения регистрации'
        };
    },

    beforeMount() {
        console.log(this.action);

        if (this.action == 'recovery') {
            this.text = 'Вам на почту выслан код восстановления'
        }
        else if (this.action == 'registration') {
            this.text = 'Вам на почту выслан код подтверждения регистрации'
        }
        else {
            this.text = 'Вам на почту выслан код подтверждения регистрации'
        }
    },

    methods: {
        onEnter() {
            if (this.isComplete) {
                this.submitForm()
            }
        },
        handleOnComplete() {
            this.isComplete = true;
        },
        async newCodeRequest() {
            this.$emit('setLoading', true)

            let formData = {
                'email': this.email,
            };

            let response = await fetch(`https://api.b2b.terexov.ru/api/auth/send_recovery_code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            let result = await response.json();

            if (result.status == 'ok') {
                this.code = ''
                this.$emit('setLoading', false)
            }
            else {
                alert('Возникла ошибка')
                this.$emit('setLoading', false)
            }
        },
        handleOnChange() {
            this.isComplete = false;
            this.isError = false
        },
        async submitForm() {
            if (this.code.length == 4) {
                this.$emit('setLoading', true)

                let formData = {
                    'email': this.email,
                    'code': this.code
                };

                console.log(formData);

                let response = await fetch(`https://api.b2b.terexov.ru/api/auth/check_code`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(formData)
                });

                let result = await response.json();

                console.log(result);

                if (result.status == 'ok') {
                    this.$emit('tokenChange', result.token)
                    this.$emit('tokenTimeChange', result.expires_in)
                    this.$emit('changeBlock', 'PasswordBlock')
                    this.$emit('setLoading', false)
                }
                else {
                    this.$emit('setLoading', false)
                    console.log('not valid');
                    this.isComplete = false;
                    this.isError = true
                }
            }
            else {
                this.isError = true
            }
        },
    }
}
</script>

<style lang="scss">
.pincode-block {
    .text {
        margin-top: 24px;
        text-align: center;
    }

    .button {
        margin-top: 24px;
    }

    .back-btn {
        margin-top: 12px;
        background-color: var(--bw4);
        color: var(--bw0);
    }

    div.vue-pincode-input-wrapper {
        margin: 24px auto 0;
        column-gap: 12px;
        justify-content: center;

        input.vue-pincode-input {
            border-radius: 4px;

            background-color: var(--bw4);

            border: 1px solid var(--bw4);

            margin-right: 0;

            width: 38px;
            height: 48px;

            @extend %DB;
            color: var(--bw0);

            &:focus {
                border-color: var(--bw1);
            }
        }
    }


}

.pincode-block {
    .text {
        margin-top: 24px;
        text-align: center;
    }

    .button {
        margin-top: 24px;
    }

    .back-btn {
        margin-top: 12px;
        background-color: var(--bw4);
        color: var(--bw0);
    }

    .pincode-wrapper {
        margin: 24px auto 0;
        column-gap: 20px;
        justify-content: center;

        .pincode-input {
            border-radius: 4px;

            background-color: var(--bw4);

            border: 1px solid var(--bw4);

            margin-right: 0;

            width: 38px;
            height: 48px;

            text-align: center;
            vertical-align: middle;

            @extend %DB;
            color: var(--bw0);

            transition: border .3s;

            &:focus {
                border-color: var(--bw1);
            }
        }

        &.complete {
            .pincode-input {
                border-color: var(--green);
            }
        }

        &.error {
            .pincode-input {
                border-color: $primary;
            }
        }
    }


}
</style>