<template>
    <section class="login-block registration-block">
        <h1>Регистрация</h1>
        <div class="input-container" :class="{ error: v$.nameValue.$errors.length }">
            <label for="name">Название организации</label>
            <input v-model="nameValue" id="name" type="text" required ref="name" autocomplete="off"
                @keyup.down="passFocus">
        </div>
        <div class="input-container" :class="{ error: v$.INNvalue.$errors.length }">
            <label for="name">ИНН</label>
            <input v-model="INNvalue" id="name" type="text" required ref="name" autocomplete="off"
                @keyup.down="passFocus">
        </div>
        <div class="input-container" :class="{ error: v$.emailValue.$errors.length }">
            <label for="name">e-mail</label>
            <input v-model="emailValue" id="name" type="text" required ref="name" autocomplete="off"
                @keyup.down="passFocus">
        </div>
        <Transition>
            <p class="error-text" v-show="isLoginExist">
                {{ errorText }}
            </p>
        </Transition>
        <button class="button" @click="submitForm">Зарегистрироваться</button>
        <button class="button back-btn" @click="$emit('changeBlock', 'loginBlock')">Назад</button>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

export default {
    name: 'registrationBlock',

    setup() {
        return { v$: useVuelidate() }
    },

    data() {
        return {
            nameValue: '',
            INNvalue: '',
            emailValue: '',
            isLoginExist: false,
            errorCode: 0
        };
    },

    emits: ['changeBlock', 'emailChange', 'actionChange', 'setLoading', 'nameChanged'],

    validations() {
        return {
            nameValue: { required },
            INNvalue: { required },
            emailValue: { email, required },
        }
    },

    computed: {
        errorText() {
            switch (this.errorCode) {
                case 0:
                    return "Email уже зарегистрован. Обратитесь к своему руководителю или в поддержку BWTools"
                case 1:
                    return "Такая компания уже зарегистрирована. Обратитесь к своему руководителю или в поддержку BWTools"
                case 2:
                    return "Компания с таким ИНН не найдена"
                default:
                    return 'Ошибка'
            }
        }
    },

    methods: {
        passFocus() {
            this.$refs.password.focus()
        },
        loginFocus() {
            this.$refs.login.focus()
        },
        async submitForm() {
            this.v$.$validate();

            if (!this.v$.$error) {
                this.$emit('setLoading', true)
                this.isLoginExist = false

                let formData = {
                    'name': this.nameValue,
                    'inn': this.INNvalue,
                    'email': this.emailValue
                };

                console.log(formData);

                let response = await fetch(`https://api.b2b.terexov.ru/api/auth/registration`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });

                let result = await response.json();

                console.log(result);

                if (result.status == 'ok') {
                    this.$emit('emailChange', this.emailValue);
                    this.$emit('nameChanged', this.nameValue);
                    this.$emit('actionChange', 'registration')
                    this.$emit('changeBlock', 'PincodeBlock')
                    this.$emit('setLoading', false)
                }
                else {
                    this.isLoginExist = true
                    if (result.message.toLowerCase().includes(("The email has already been").toLowerCase())) {
                        this.errorCode = 0
                    }
                    if (result.message.toLowerCase().includes(("Company with INN").toLowerCase())) {
                        this.errorCode = 1
                    }
                    if (result.message.toLowerCase().includes(("Не найдено компании по ИНН").toLowerCase())) {
                        this.errorCode = 2
                    }
                    this.$emit('setLoading', false)
                }
            }
            else {
                console.log('not valid');
            }
        },

        checkEnter(event) {
            console.log(event);

            if (event.key === "Enter") {
                this.submitForm()
            }
        },
    }
}
</script>

<style lang="scss">
.registration-block {
    .input-container {
        margin-top: 16px;
    }

    .back-btn {
        margin-top: 12px;
        background-color: var(--bw4);
        color: var(--bw0);
    }
}
</style>