<template>
    <div class="notification-item">
        <div class="notification-item__header">
            <svg class="info" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                    stroke="#035AA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M13.5 8C13.5 8.82843 12.8284 9.5 12 9.5C11.1716 9.5 10.5 8.82843 10.5 8C10.5 7.17157 11.1716 6.5 12 6.5C12.8284 6.5 13.5 7.17157 13.5 8Z"
                    fill="#035AA6" />
                <path d="M12 12V16" stroke="#035AA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p>{{ title }}</p>
            <svg class="close" @click="$emit('remove', id)" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                    stroke="#B6BEC9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15 9L9 15" stroke="#B6BEC9" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M9 9L15 15" stroke="#B6BEC9" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>
        <p class="notification-item__text">{{ text }}</p>
        <div class="notification-item__bottom">

        </div>
    </div>
</template>

<script>
export default {
    name: 'NotificationItem',

    props: {
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        title: {
            type: String,
            default() {
                return ''
            }
        },
        text: {
            type: String,
            default() {
                return ''
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        },
    },

    emits: ['remove'],

    computed: {
        isPaymentTerm() {
            if (this.paymentTerm != "none") {
                return true
            }
            else {
                return false
            }
        }
    }
}
</script>

<style lang="scss">
.notification-item {
    width: 100%;

    padding: 12px;

    border: 1px solid $bw3;

    border-radius: 4px;

    background-color: $bw6;

    &__header {
        display: flex;
        align-items: center;
        column-gap: 8px;

        .close {
            margin-left: auto;

            cursor: pointer;

            path {
                stroke: $bw3;
                transition: stroke .3s;
            }

            &:hover {
                path {
                    stroke: $primary;
                }
            }
        }

        svg {
            flex-shrink: 0;
        }
    }

    &__text {
        margin-top: 8px;

        @extend %text-regular;
        color: $bw0;
    }
}
</style>