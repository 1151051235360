import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useNotifications = defineStore('notifications', () => {
  const notifications = ref([])

  const storageNotification = sessionStorage.getItem('notifications')

  if (storageNotification) {
    notifications.value = JSON.parse(storageNotification)
  }

  watch(notifications, async (newVal, oldVal) => {
    sessionStorage.setItem('notifications', JSON.stringify(newVal))
  })

  return {
    notifications
  }
})
