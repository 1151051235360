import { defineStore } from 'pinia'
import { ref, computed, watch } from 'vue'

export const useUserToken = defineStore('userToken', () => {
  const token = ref('')
  const tokenTime = ref(0)
  const tokenLiveTime = ref(0)
  const regStatus = ref('')
  const userEmail = ref('')
  const companyName = ref('')
  const companyInn = ref('')
  const firstProfileEnter = ref(false)
  const logged = ref(false)

  const storageToken = sessionStorage.getItem('token')
  const storageTokenTime = sessionStorage.getItem('tokenTime')
  const storageTokenLiveTime = sessionStorage.getItem('tokenLiveTime')
  const storeRegStatus = sessionStorage.getItem('regStatus')
  const storeUserEmail = sessionStorage.getItem('userEmail')
  const storageCompanyName = sessionStorage.getItem('companyName')
  const storageCompanyInn = sessionStorage.getItem('companyInn')

  if (storeRegStatus) {
    regStatus.value = JSON.parse(storeRegStatus)
  } else {
    regStatus.value = 2
  }

  if (storageCompanyInn) {
    companyInn.value = JSON.parse(storageCompanyInn)
  } else {
    companyInn.value = '00000'
  }

  if (storageCompanyName) {
    companyName.value = JSON.parse(storageCompanyName)
  } else {
    companyName.value = 'Название компании'
  }

  if (storageToken) {
    token.value = JSON.parse(storageToken)
  }

  if (storeUserEmail) {
    userEmail.value = JSON.parse(storeUserEmail)
  }

  if (storageTokenLiveTime) {
    tokenLiveTime.value = JSON.parse(storageTokenLiveTime)
  }

  if (storageTokenTime) {
    tokenTime.value = JSON.parse(storageTokenTime)
  }
  // else {
  //   token.value = 'token'
  // }

  firstProfileEnter.value = false

  if (token.value != '') {
    logged.value = true
  }

  watch(token, async (newToken, oldToken) => {
    sessionStorage.setItem('token', JSON.stringify(newToken))
  })

  watch(tokenTime, async (newToken, oldToken) => {
    console.log('watch' + newToken)

    sessionStorage.setItem('tokenTime', JSON.stringify(newToken))
  })

  watch(tokenLiveTime, async (newToken, oldToken) => {
    sessionStorage.setItem('tokenLiveTime', JSON.stringify(newToken))
  })

  watch(regStatus, async (newVal, oldVal) => {
    sessionStorage.setItem('regStatus', JSON.stringify(newVal))
  })

  watch(userEmail, async (newVal, oldVal) => {
    sessionStorage.setItem('userEmail', JSON.stringify(newVal))
  })

  watch(companyInn, async (newVal, oldVal) => {
    sessionStorage.setItem('companyInn', JSON.stringify(newVal))
  })

  watch(companyName, async (newVal, oldVal) => {
    sessionStorage.setItem('companyName', JSON.stringify(newVal))
  })

  return {
    token,
    tokenTime,
    tokenLiveTime,
    regStatus,
    userEmail,
    companyName,
    companyInn,
    firstProfileEnter,
    logged
  }
})
