<template>
    <div class="notification-container">
        <template v-for="notification in notifications">
            <Transition>
                <notification-item :key="notification.id" :title="notification.title" :text="notification.text"
                    :id="notification.id" :type="notification.type" @remove="onRemove"></notification-item>
            </Transition>
        </template>
    </div>
</template>

<script>
import NotificationItem from './NotificationItem.vue'

import { mapWritableState } from 'pinia'

import { useNotifications } from '@/stores/notifications'

import { serverRequest } from '@/api/request';

export default {
    name: 'NotificationContainer',

    components: {
        NotificationItem
    },

    props: {
        number: {
            type: Number,
            default() {
                return 0
            }
        },
    },

    computed: {
        ...mapWritableState(useNotifications, { notifications: 'notifications' }),
    },

    beforeMount() {
        this.getNotifications()
    },

    methods: {
        async onRemove(id) {
            let formData = {
                id: id,
            }

            let result = await serverRequest('POST', 'json', 'read_notification', formData)

            if (result.status == 'ok') {
                this.notifications = this.notifications.filter(item => item.id != id)
            }
        },

        async getNotifications() {
            let result = await serverRequest('GET', 'json', 'get_notifications')

            if (result != 'error' && result != 'unathorized') {
                this.notifications = result.notifications

                // this.notifications = [
                //     {
                //         text: 'Текст уведомления 123312',
                //         id: 0,
                //         type: 0
                //     },
                //     {
                //         text: 'Текст уведомления 5646456',
                //         id: 1,
                //         type: 0
                //     },
                //     {
                //         text: 'Текст уведомления 909889989',
                //         id: 2,
                //         type: 0
                //     },
                // ]
            }

            console.log(this.notifications);

        },
    }
}
</script>

<style lang="scss">
.notification-container {
    position: fixed;
    right: 70px;
    bottom: 40px;

    display: flex;
    flex-direction: column;
    row-gap: 16px;

    width: 100%;
    max-width: 400px;

    z-index: 3;
}
</style>