<template>
    <section class="order-item">
        <div class="order-item__header">
            <p class="order-item__number">Заказ №{{ number }}</p>
            <status-container :statuses="status"></status-container>
        </div>
        <div class="order-item__info first">
            <p class="order-item__info__label">Дата</p>
            <p class="order-item__info__value">{{ date }}</p>
        </div>
        <div class="order-item__info">
            <p class="order-item__info__label">Тип доставки</p>
            <p class="order-item__info__value">{{ storeGetDeliveryText(delivery_type) }}</p>
        </div>
        <div class="order-item__info">
            <p class="order-item__info__label">Сумма</p>
            <p class="order-item__info__value">{{ parseInt(sum).toLocaleString('ru-RU') }} ₽</p>
        </div>
        <p class="order-item__positions-header">{{ positionLenght }} {{ getNumWord }}</p>
        <div class="order-item__positions-container">
            <svg v-show="isSliderNav" class="positions-slider-button previous" ref="prev" width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 17L15 12L10 7" stroke="black" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
            <swiper class="positions-slider" :navigation="{
                prevEl: prev,
                nextEl: next,
            }" :modules="modules" :slides-per-view="'auto'" :space-between="12">
                <swiper-slide v-for="position in positions">
                    <router-link class="order-item__position" :to="{ name: 'product', params: { id: position.id } }">
                        <img class="order-item__position-img" :src="position.images[0]" alt="position image">
                    </router-link>
                </swiper-slide>
            </swiper>
            <svg v-show="isSliderNav" class="positions-slider-button next" ref="next" width="24" height="24"
                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 17L15 12L10 7" stroke="black" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>
        <router-link class="order-item__link" :to="{ name: 'order-item', params: { id: number } }">
            <span>Узнать подробнее о заказе</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 12H21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16 7L21 12L16 17" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </router-link>
    </section>
</template>

<script>
import StatusContainer from './StatusContainer.vue';
import { mapState, mapActions } from 'pinia'
import { useOrderStatuses } from '@/stores/orderStatuses';
import { useDeliveryTypes } from '@/stores/deliveryTypes'

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';

export default {
    name: 'order-item',

    components: {
        StatusContainer,
        Swiper,
        SwiperSlide
    },

    props: {
        number: {
            type: Number,
            default() {
                return 0
            }
        },
        status: {
            type: Array,
            default() {
                return []
            }
        },
        date: {
            type: String,
            default() {
                return "01.01.2023"
            }
        },
        delivery_type: {
            type: Number,
            default() {
                return 1
            }
        },
        sum: {
            type: Number,
            default() {
                return 0
            }
        },
        positions: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {
            words: ['позиция', 'позиции', 'позиций'],
            prev: null,
            next: null,
            modules: [Navigation],
        }
    },

    mounted() {
        this.prev = this.$refs.prev
        this.next = this.$refs.next
    },

    beforeUnmount() {

    },

    computed: {
        ...mapState(useOrderStatuses, { storeStatuses: 'statuses' }),

        positionLenght() {
            return this.positions.length
        },

        isSliderNav() {
            if (this.positionLenght > 4) {
                return true
            }
            else {
                return false
            }
        },

        getNumWord() {
            let value = Math.abs(this.positionLenght) % 100;
            let num = value % 10;
            if (value > 10 && value < 20) return this.words[2];
            if (num > 1 && num < 5) return this.words[1];
            if (num == 1) return this.words[0];
            return this.words[2];
        }
    },

    methods: {
        ...mapActions(useOrderStatuses, { storeGetStatusText: 'getStatusText' }),
        ...mapActions(useDeliveryTypes, { storeGetDeliveryText: 'getDeliveryText' }),

    }

}
</script>

<style lang="scss">
.order-item {
    border-radius: 6px;

    background-color: var(--bw6);

    padding: 16px 24px 32px 16px;

    min-width: 0;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center
    }

    &__number {
        @extend %text-medium;
        color: var(--bw0);
    }

    &__status {
        border-radius: 2px;
        border: 1px solid transparent;

        padding: 0 8px;

        @extend %text-regular;
        color: var(--bw0);

        &.status-1 {
            border-color: #FF964A;
            background-color: #FFF0E5;
            color: #FF964A;
        }
    }

    &__info {
        margin-top: 12px;

        &.first {
            margin-top: 20px;
        }

        display: flex;
        column-gap: 12px;
        align-items: center;

        &__label {
            @extend %text-regular;
            color: var(--bw1);
            font-weight: 400;
        }

        &__value {
            @extend %text-regular;
            color: var(--bw0);
        }
    }

    &__positions-header {
        margin-top: 20px;

        @extend %text-regular;
        color: var(--bw1);
    }

    &__positions-container {
        margin-top: 12px;

        width: 390px;
        height: 72px;
        min-width: 0;

        display: flex;
        column-gap: 10px;
        align-items: center;
    }

    .positions-slider {
        width: 100%;
        min-width: 0;
        height: 100%;

        .swiper-wrapper {
            height: 100%;
        }

        .swiper-slide {
            height: 100%;
            width: fit-content;
        }
    }

    .positions-slider-button {
        cursor: pointer;

        &.previous {
            transform: rotate(180deg);
        }

        &.swiper-button-disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    &__position-img {
        border-radius: 4px;
        border: 1px solid var(--bw3);
        padding: 7px 5px;
        width: 72px;
        height: 100%;
    }

    &__link {
        margin-top: 20px;

        @extend %button;
        display: flex;
        column-gap: 12px;

        width: fit-content;
    }
}

@media screen and (max-width: 1600px) {
    .order-item {
        padding: 16px 20px;
    }
}

@media screen and (max-width: 1300px) {
    .order-item {
        padding: 16px 24px 32px 16px;
    }
}
</style>