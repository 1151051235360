<template>
    <div class="status-block" :class="getStatus">
        {{ text }}
    </div>
</template>

<script>
export default {
    name: 'StatusBlock',

    props: {
        text: {
            type: String,
            default() {
                return "Статус"
            }
        },
        code: {
            type: Number,
            default() {
                return 1
            }
        },
        type: {
            type: String,
            default() {
                return 'payments'
            }
        },
    },

    computed: {
        getStatus() {
            if (this.type == 'payments') {
                if (this.code == 2 || this.code == 7 || this.code == 8 || this.code == 9) {
                    return 'status' + '-' + 'red'
                }
                else if (this.code == 3 || this.code == 4 || this.code == 5) {
                    return 'status' + '-' + 'orange'
                }
                else if (this.code == 10 || this.code == 12 || this.code == 13 || this.code == 14) {
                    return 'status' + '-' + 'green'
                }
                else if (this.code == 0 || this.code == 11 || this.code == 6) {
                    return 'status' + '-' + 'gray'
                }
                else if (this.code == 1) {
                    return 'status' + '-' + 'blue'
                }
                else {
                    return 'status' + '-' + 'gray'
                }
            }
            else if (this.type == 'complaints') {
                if (this.code == 1) {
                    return 'status' + '-' + 'orange'
                }
                else if (this.code == 2) {
                    return 'status' + '-' + 'green'
                }
                else {
                    return 'status' + '-' + 'gray'
                }
            }
            else if (this.type == 'bills') {
                if (this.code == 4) {
                    return 'status' + '-' + 'red'
                }
                else if (this.code == 1 || this.code == 2) {
                    return 'status' + '-' + 'orange'
                }
                else if (this.code == 3) {
                    return 'status' + '-' + 'green'
                }
                else {
                    return 'status' + '-' + 'gray'
                }
            }
            else if (this.type == 'appeals') {
                if (this.code == 0) {
                    return 'status' + '-' + 'orange'
                }
                else if (this.code == 1) {
                    return 'status' + '-' + 'green'
                }
                else {
                    return 'status' + '-' + 'gray'
                }
            }
            else if (this.type == 'questions') {
                if (this.code == 0) {
                    return 'status' + '-' + 'orange'
                }
                else if (this.code == 1) {
                    return 'status' + '-' + 'green'
                }
                else if (this.code == 2) {
                    return 'status' + '-' + 'orange'
                }
                else {
                    return 'status' + '-' + 'gray'
                }
            }
            else if (this.type == 'appeals') {
                if (this.code == 0) {
                    return 'status' + '-' + 'orange'
                }
                else if (this.code == 1) {
                    return 'status' + '-' + 'green'
                }
                else {
                    return 'status' + '-' + 'gray'
                }
            }
        }
    }
}
</script>

<style lang="scss">
.status-block {
    border-radius: 2px;
    border: 1px solid transparent;
    background-color: transparent;
    padding: 0 8px;

    width: fit-content;


    white-space: nowrap;

    &.status-orange {
        color: var(--orange);
        border-color: var(--orange);
        background-color: var(--orange-bg)
    }

    &.status-blue {
        color: #035AA6;
        border-color: #035AA6;
        background-color: #E6F3FF;
    }

    &.status-green {
        color: var(--green);
        border-color: var(--green);
        background-color: var(--green-bg)
    }

    &.status-red {
        color: var(--primary);
        border-color: var(--primary);
        background-color: var(--primary-bg)
    }

    &.status-gray {
        color: var(--bw1);
        border-color: var(--bw1);
        background-color: var(--bw4)
    }
}
</style>