<template>
    <form class="login-block pincode-block">
        <h1>Придумайте новый пароль</h1>
        <div class="input-wrapper">
            <div class="input-container" :class="{ error: v$.passwordValue.$errors.length }">
                <input v-model="passwordValue" :type="passwordType" class="password-input" id="new-password" required
                    autocomplete="off" ref="password" @keyup.up="loginFocus" placeholder="Введите пароль">
                <svg class="show-password" @click="togglePassword" :class="{ 'showed': passwordType == 'text' }" width="24"
                    height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.7 12.0002C14.7 13.4914 13.4912 14.7002 12 14.7002C10.5088 14.7002 9.3 13.4914 9.3 12.0002C9.3 10.509 10.5088 9.3002 12 9.3002C13.4912 9.3002 14.7 10.509 14.7 12.0002Z"
                        stroke="#424F60" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M3 12.0002C4.44012 8.31257 7.80243 5.7002 12 5.7002C16.1976 5.7002 19.5599 8.31258 21 12.0002C19.5599 15.6878 16.1976 18.3002 12 18.3002C7.80243 18.3002 4.44012 15.6878 3 12.0002Z"
                        stroke="#424F60" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <div class="input-container" :class="{ error: v$.repeatPasswordValue.$errors.length }">
                <input v-model="repeatPasswordValue" :type="passwordRepeatType" class="password-input" id="login-password"
                    required autocomplete="off" ref="repeat-password" @keyup.up="loginFocus"
                    placeholder="Введите пароль еще раз">
                <svg class="show-password" @click="toggleRepeatPassword" :class="{ 'showed': passwordRepeatType == 'text' }"
                    width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.7 12.0002C14.7 13.4914 13.4912 14.7002 12 14.7002C10.5088 14.7002 9.3 13.4914 9.3 12.0002C9.3 10.509 10.5088 9.3002 12 9.3002C13.4912 9.3002 14.7 10.509 14.7 12.0002Z"
                        stroke="#424F60" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M3 12.0002C4.44012 8.31257 7.80243 5.7002 12 5.7002C16.1976 5.7002 19.5599 8.31258 21 12.0002C19.5599 15.6878 16.1976 18.3002 12 18.3002C7.80243 18.3002 4.44012 15.6878 3 12.0002Z"
                        stroke="#424F60" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>
        <button class="button" type="button" @click="submitForm">Подтвердить</button>
        <!-- <button class="button back-btn" @click="$emit('changeBlock', 'loginBlock')">Назад</button> -->
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { mapWritableState } from 'pinia'
import { useUserToken } from '@/stores/userToken'

export default {
    name: 'PasswordBlock',

    setup() {
        return { v$: useVuelidate() }
    },

    props: {
        action: {
            type: String,
            default() {
                return ''
            }
        },
        email: {
            type: String,
            default() {
                return ''
            }
        },
        token: {
            type: String,
            default() {
                return ''
            }
        }
    },

    emits: ['changeBlock', 'setLoading', 'regStatusChange'],

    data() {
        return {
            passwordValue: '',
            repeatPasswordValue: '',
            passwordType: 'password',
            passwordRepeatType: 'password',
        };
    },

    validations() {
        return {
            passwordValue: { required },
            repeatPasswordValue: { required },
        }
    },

    computed: {
        ...mapWritableState(useUserToken, { storeFirstProfileEnter: 'firstProfileEnter' }),
    },

    methods: {
        togglePassword() {
            if (this.passwordType == 'password') {
                this.passwordType = 'text'
            }
            else {
                this.passwordType = 'password'
            }
        },

        toggleRepeatPassword() {
            if (this.passwordRepeatType == 'password') {
                this.passwordRepeatType = 'text'
            }
            else {
                this.passwordRepeatType = 'password'
            }
        },

        async submitForm() {
            this.v$.$validate();

            if (!this.v$.$error) {
                if (this.passwordValue == this.repeatPasswordValue) {
                    this.$emit('setLoading', true)

                    console.log(this.email);
                    console.log(this.action);
                    console.log(this.token);

                    let formData = {
                        'password': this.passwordValue
                    };

                    console.log(formData);

                    let response = await fetch(`https://api.b2b.terexov.ru/api/auth/set_password`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + this.token
                        },
                        body: JSON.stringify(formData)
                    });

                    let result = await response.json();

                    console.log(result);

                    if (result.status == 'ok') {
                        this.storeFirstProfileEnter = true
                        this.$emit('setLoading', false)
                        this.$emit('regStatusChange', 2)
                        this.$router.push({ name: 'profile' })
                    }
                    else {
                        this.$emit('setLoading', false)
                        console.log('not valid');
                    }
                }
            }
            else {
                console.log('not valid');
            }
        },
    }
}
</script>

<style lang="scss">
.pincode-block {
    .text {
        margin-top: 24px;
        text-align: center;
    }

    .button {
        margin-top: 24px;
    }

    .back-btn {
        margin-top: 12px;
        background-color: var(--bw4);
        color: var(--bw0);
    }

    div.vue-pincode-input-wrapper {
        margin: 24px auto 0;
        column-gap: 12px;
        justify-content: center;

        input.vue-pincode-input {
            border-radius: 4px;

            background-color: var(--bw4);

            border: 1px solid var(--bw4);

            margin-right: 0;

            width: 38px;
            height: 48px;

            @extend %DB;
            color: var(--bw0);

            &:focus {
                border-color: var(--bw1);
            }
        }
    }


}
</style>