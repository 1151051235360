<template>
    <section class="supplies-block">
        <alert-block v-if="regStatus != 1"
            :text="'После прохождения модерации здесь появится Договор поставки'"></alert-block>
        <template v-if="regStatus == 1">
            <alert-block v-if="signatureStatus == 2" :text="'Договор подписан'" :color="'green'"></alert-block>
            <alert-block v-if="signatureStatus == 3" :text="'Договор утверждён'" :color="'green'"></alert-block>
            <alert-block v-if="storeChangeTerms" :text="'Запрошено изменение условий поставки'"
                :color="'orange'"></alert-block>
            <alert-block v-show="currentFiles.length > 0 && signatureStatus == 1"
                :text="'Скачайте договор и загрузите подписанную копию'" :color="'red'"></alert-block>
            <a v-for="file in currentFiles" class="download-link" :href="file.link" download target="_blank">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                        stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
                </svg>
                <span>{{ file.name }}</span>
            </a>
            <!-- <a class="download-link" href="/" download="">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                    stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
            </svg>
            <span>Доп. соглашение №2 к договору от 20/01/2020 </span>
        </a> -->
            <v-checkbox v-show="currentFiles.length > 0 && signatureStatus == 1" class="sign-checkbox"
                :checkbox-id="'sign-checkbox'" :checkbox-name="'sign-checkbox'" :checkbox-label="'Подписать по ЭДО'"
                :checkbox-value="'sign-checkbox'" @changed="onSign"></v-checkbox>
            <div class="button-container">
                <template v-show="currentFiles.length > 0 && signatureStatus == 1">
                    <button v-if="ediSign == true" class="document-button" @click="signOnEdi">Сохранить</button>
                    <file-button-upload v-else @file-uploaded="onFileUploaded" @file-send="onFileSend"
                        @file-deleted="onFileDeleted" :formats="'.doc,.docx,.png,.jpg,.jpeg,.pdf'"></file-button-upload>
                </template>
                <button v-show="signatureStatus != 1" class="document-button" :class="{ 'disabled': storeChangeTerms }"
                    @click="changeTerms">
                    <template v-if="!storeChangeTerms">Запросить изменение условий</template>
                    <template v-else>Запрос изменения условий отправлен</template>
                </button>
            </div>
            <section class="doc-history" v-if="fileHistory.length > 0">
                <h2>История договоров</h2>
                <div v-for="file in fileHistory" class="doc-history-item">
                    <p class="doc-history-item__date">{{ file.date }}</p>
                    <a class="download-link" :href="file.link" download target="_blank">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                                stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
                        </svg>
                        <span>{{ file.name }}</span>
                    </a>
                </div>
                <!-- <div class="doc-history-item">
                <p class="doc-history-item__date">28/01/2020</p>
                <a class="download-link" href="/" download>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                            stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
                    </svg>
                    <span>Доп. соглашение №2 к договору от 20/01/2020.pdf</span>
                </a>
            </div>
            <div class="doc-history-item">
                <p class="doc-history-item__date">28/01/2020</p>
                <a class="download-link" href="/" download>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                            stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
                    </svg>
                    <span>Доп. соглашение №2 к договору от 20/01/2020.pdf</span>
                </a>
            </div>
            <div class="doc-history-item">
                <p class="doc-history-item__date">28/01/2020</p>
                <a class="download-link" href="/" download>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                            stroke="#035AA6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
                    </svg>
                    <span>Доп. соглашение №2 к договору от 20/01/2020.pdf</span>
                </a>
            </div> -->
            </section>
        </template>
    </section>
</template>

<script>
import AlertBlock from '../AlertBlock.vue';
import vCheckbox from '../v-checkbox.vue';
import FileButtonUpload from '../FileButtonUpload.vue';

import { serverRequest } from '../../api/request';

import { mapWritableState } from 'pinia'
import { useTempStates } from '../../stores/tempStates';
import { useUserToken } from '@/stores/userToken'

export default {
    name: 'SuppliesBlock',

    components: {
        AlertBlock,
        vCheckbox,
        FileButtonUpload
    },

    props: {
        userToken: {
            type: String,
            default() {
                return ''
            }
        },
    },

    emits: ['setLoading'],

    data() {
        return {
            file: null,
            signatureStatus: 1,
            currentFiles: [],
            fileHistory: [],
            ediSign: false,
        };
    },

    beforeMount() {
        this.getData()
    },

    computed: {
        ...mapWritableState(useUserToken, { regStatus: 'regStatus' }),
        ...mapWritableState(useTempStates, { storeChangeTerms: 'changeTermsRequest' }),


    },

    methods: {
        onFileUploaded(file) {
            this.file = file
        },

        onFileDeleted() {
            this.file = null
        },

        onSign(id, status) {
            this.ediSign = status
        },

        async signOnEdi() {
            this.$emit('setLoading', true)

            let result = await serverRequest('GET', 'json', 'documents/sign_via_edi')

            if (result != 'error' && result != 'unathorized') {
                this.getData()
            }
        },

        async onFileSend(file) {
            console.log(file);

            this.$emit('setLoading', true)

            let formData = new FormData();

            formData.append('file', file)

            for (let [name, value] of formData) {
                console.log(`${name} = ${value}`);
            }

            let result = await serverRequest('POST', 'formData', 'documents/upload_agreement_scan', formData)

            if (result != 'error' && result != 'unathorized') {
                this.getData()
            }
        },

        async changeTerms() {
            this.$emit('setLoading', true)

            let result = await serverRequest('GET', 'json', 'profile/change_conditions_request')

            if (result != 'error' && result != 'unathorized') {
                this.storeChangeTerms = true
            }
            else {
                //alert('Ошибка запроса')
            }

            this.$emit('setLoading', false)
        },

        async getData() {
            this.$emit('setLoading', true)

            let result = await serverRequest('POST', 'json', 'documents/get_supply_agreements')

            if (result != 'error' && result != 'unathorized') {
                this.signatureStatus = result.signature_status
                this.currentFiles = result.current_files
                this.fileHistory = result.history

                this.$emit('setLoading', false)
            }
            else {
                //alert('Ошибка запроса')
            }
        }
    }
}
</script>

<style lang="scss">
.supplies-block {
    .alert-block {
        margin-top: 12px;

        &:first-of-type {
            margin-top: 24px;
        }

    }

    .download-link {
        display: flex;
        align-items: center;
        column-gap: 8px;

        margin-top: 16px;

        &:first-of-type {
            margin-top: 24px;
        }

        span {
            @extend %text-regular;
            color: var(--blue);
        }

        svg {
            flex-shrink: 0;
        }
    }

    .sign-checkbox {
        margin-top: 24px;

        margin-left: 4px;
    }

    .button-container {
        margin-top: 16px;

        display: flex;
        flex-direction: column;
        column-gap: 24px;
        row-gap: 12px;
        align-items: flex-start;


    }

    .document-button {
        @extend %button;

        display: flex;
        column-gap: 12px;
        align-items: center;

        width: fit-content;

        padding: 11px 15px;

        font-weight: 300;
    }

    .doc-history {
        margin-top: 48px;

        width: 100%;

        max-width: 630px;

        h2 {
            @extend %text-medium;
            color: $bw0;
        }
    }

    .doc-history-item {
        display: flex;
        column-gap: 40px;
        align-items: center;

        padding: 12px 16px;

        border-radius: 6px;
        border: 1px solid $bw3;

        margin-top: 12px;

        &__date {
            min-width: 100px;
            @extend %text-regular;
            color: $bw0;
        }

        .download-link {
            margin-top: 0;
        }
    }
}
</style>