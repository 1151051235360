<template>
    <form class="login-block">
        <h1>Для работы необходимо авторизоваться</h1>
        <div class="input-container" :class="{ error: v$.loginValue.$errors.length }">
            <input v-model="loginValue" id="login" type="text" required ref="login" autocomplete="off"
                @keyup.down="passFocus" placeholder="Введите логин" @keypress.enter="submitForm">
        </div>
        <div class="input-container" :class="{ error: v$.passwordValue.$errors.length }">
            <input v-model="passwordValue" id="login-password" :type="passwordType" class="password-input" required
                autocomplete="off" ref="password" @keyup.up="loginFocus" placeholder="Введите пароль"
                @keypress.enter="submitForm">
            <svg class="show-password" @click="togglePassword" :class="{ 'showed': passwordType == 'text' }" width="24"
                height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.7 12.0002C14.7 13.4914 13.4912 14.7002 12 14.7002C10.5088 14.7002 9.3 13.4914 9.3 12.0002C9.3 10.509 10.5088 9.3002 12 9.3002C13.4912 9.3002 14.7 10.509 14.7 12.0002Z"
                    stroke="#424F60" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M3 12.0002C4.44012 8.31257 7.80243 5.7002 12 5.7002C16.1976 5.7002 19.5599 8.31258 21 12.0002C19.5599 15.6878 16.1976 18.3002 12 18.3002C7.80243 18.3002 4.44012 15.6878 3 12.0002Z"
                    stroke="#424F60" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <Transition>
            <p class="error-text" v-show="isWrongLogin">Неверный логин и/или пароль</p>
        </Transition>
        <button class="forgot-password" type="button" @click="$emit('changeBlock', 'RecoveryBlock')">Забыли
            пароль?</button>
        <button class="button" type="button" @click="submitForm">Войти</button>
        <p class="no-account">Нет аккаунта? <button class="registration" type="button"
                @click="$emit('changeBlock', 'RegistrationBlock')">Зарегистрироваться</button></p>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
    name: 'loginBlock',

    setup() {
        return { v$: useVuelidate() }
    },

    data() {
        return {
            loginValue: '',
            passwordValue: '',
            isWrongLogin: false,
            passwordType: 'password'
        };
    },


    emits: ['changeBlock', 'tokenChange', 'regStatusChange', 'setLoading', 'nameChanged', 'innChanged', 'tokenTimeChange'],

    validations() {
        return {
            loginValue: { required, email },
            passwordValue: { required },
        }
    },

    methods: {
        passFocus() {
            this.$refs.password.focus()
        },

        loginFocus() {
            this.$refs.login.focus()
        },

        togglePassword() {
            if (this.passwordType == 'password') {
                this.passwordType = 'text'
            }
            else {
                this.passwordType = 'password'
            }
        },

        async submitForm() {
            this.v$.$validate();

            this.isWrongLogin = false

            if (!this.v$.$error) {
                this.$emit('setLoading', true)

                let formData = {
                    'login': this.loginValue,
                    'password': this.passwordValue
                };

                console.log(formData);

                let response = await fetch(`https://api.b2b.terexov.ru/api/auth/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(formData)
                });

                let result = await response.json();

                console.log(result);

                if (result.status == 'ok') {
                    this.$emit('tokenChange', result.token)
                    this.$emit('tokenTimeChange', result.expires_in)
                    this.$emit('emailChange', this.loginValue)
                    this.$emit('regStatusChange', result.moderation)
                    this.$emit('nameChanged', result.company_name)
                    this.$emit('innChanged', result.company_inn)
                    this.$emit('setLoading', false)
                    this.$router.push({ name: 'home' })
                }
                else {
                    this.$emit('setLoading', false)
                    this.isWrongLogin = true
                    console.log('not valid');
                }
            }
            else {
                console.log('not valid');
            }
        },

        checkEnter(event) {
            console.log(event);

            if (event.key === "Enter") {
                this.submitForm()
            }
        },
    }
}
</script>

<style lang="scss">
.login-block {
    min-width: 320px;
    max-width: 100%;

    display: flex;
    flex-direction: column;

    border-radius: 8px;

    h1 {
        @extend %H2;
        color: var(--bw0);

        text-align: center;
    }

    .input-container {
        width: 100%;

        position: relative;

        margin-top: 12px;

        @extend %input-container;

        &:first-of-type {
            margin-top: 32px;
        }

        .show-password {
            position: absolute;
            right: 16px;

            top: 50%;
            transform: translateY(-50%);

            cursor: pointer;

            path {
                stroke: #424F60;
                transition: stroke .3s;
            }

            &.showed {
                path {
                    stroke: $primary;
                }
            }
        }

        .password-input {
            margin-top: 0;
            padding-right: 46px;
        }

        .input-errors {
            position: absolute;
            left: 10px;
            bottom: 0px;

            @extend %text-regular;
            color: var(--error);

            opacity: 0;

            transition: opacity .4s, bottom .4s;
        }

        &.error {
            input {
                border-color: var(--error);
            }

            .input-errors {
                bottom: -20px;
                opacity: 1;
            }

        }
    }

    .forgot-password {
        margin: 12px auto 0;

        text-align: center;

        display: inline-block;

        @extend %text-medium;
        color: var(--primary);

        background-color: transparent;
    }

    .error-text {
        color: $primary;
        margin-top: 12px;
        @extend %text-regular;
    }

    .no-account {
        margin-top: 16px;

        text-align: center;
    }

    .registration {
        @extend %text-medium;
        color: var(--primary);

        background-color: transparent;
    }

    .button {
        margin-top: 32px;

        @extend %button;
    }
}
</style>