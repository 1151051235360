<template>
    <section class="login-block registration-block">
        <h1>Введите почту, к которой привязан аккаунт организации</h1>
        <div class="input-container" :class="{ error: v$.emailValue.$errors.length }">
            <input v-model="emailValue" id="recoveryEmail" type="text" required ref="name" autocomplete="off"
                @keyup.down="passFocus" placeholder="e-mail">
        </div>
        <Transition>
            <p class="error-text" v-show="isWrongLogin">Аккаунт с данной почтой не найден</p>
        </Transition>
        <button class="button" @click="submitForm">Подтвердить</button>
        <button class="button back-btn" @click="$emit('changeBlock', 'loginBlock')">Назад</button>
    </section>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

export default {
    name: 'RecoveryBlock',

    setup() {
        return { v$: useVuelidate() }
    },

    data() {
        return {
            isRegistration: true,
            emailValue: '',
            isWrongLogin: false
        };
    },

    emits: ['changeBlock', 'emailChange', 'actionChange', 'setLoading'],

    validations() {
        return {
            emailValue: { email, required },
        }
    },

    methods: {
        passFocus() {
            this.$refs.password.focus()
        },
        loginFocus() {
            this.$refs.login.focus()
        },

        async submitForm() {
            this.v$.$validate();

            if (!this.v$.$error) {
                this.$emit('setLoading', true)
                this.isWrongLogin = false

                let formData = {
                    'email': this.emailValue,
                };

                console.log(formData);

                let response = await fetch(`https://api.b2b.terexov.ru/api/auth/send_recovery_code`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(formData)
                });

                let result = await response.json();

                console.log(result);

                if (result.status == 'ok') {
                    this.$emit('emailChange', this.emailValue)
                    this.$emit('actionChange', 'recovery')
                    this.$emit('changeBlock', 'PincodeBlock')
                    this.$emit('setLoading', false)
                }
                else {
                    console.log('not valid');
                    this.isWrongLogin = true
                    this.$emit('setLoading', false)
                }
            }
            else {
                console.log('not valid');
            }
        },

        checkEnter(event) {
            console.log(event);

            if (event.key === "Enter") {
                this.submitForm()
            }
        },
    }
}
</script>

<style lang="scss"></style>