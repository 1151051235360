<template>
  <div class="order-history-page" :class="{ 'loading': isLoading }">
    <v-loading :is-loading="isLoading"></v-loading>
    <div class="order-history-page__header">
      <custom-header :title="'Мои заказы'"></custom-header>
      <info-tooltip class="header-info" :text="'Мои заказы'"></info-tooltip>
    </div>
    <router-link class="new-order-btn" :to="{ name: 'specifications' }">
      <span>Разместить новый заказ</span>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 12H5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 5V19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </router-link>
    <div class="filter-container">
      <div class="search-input-container">
        <input type="text" placeholder="Введите номер заявки или автора заказа" v-model="searchValue"
          @keypress.enter="searchOrders" @blur="blurHandler">
        <svg @click="searchOrders" width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21 21L16.6569 16.6569M16.6569 16.6569C18.1046 15.2091 19 13.2091 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19C13.2091 19 15.2091 18.1046 16.6569 16.6569Z"
            stroke="#424F60" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <v-calendar @date-change="onDateChange" @date-clear="onDateClear" :is-range="true">
        <div class="date-pick">
          <span v-if="showedDate == null">Сортировать по дате</span>
          <span v-else>{{ showedDate }}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 8H20" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 3V5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 3V5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V6Z"
              stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 16.5H16.002V16.502H16V16.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M12 16.5H12.002V16.502H12V16.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M8 16.5H8.002V16.502H8V16.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M16 12.5H16.002V12.502H16V12.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M12 12.5H12.002V12.502H12V12.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M8 12.5H8.002V12.502H8V12.5Z" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
      </v-calendar>
    </div>
    <div class="order-category-block">
      <swiper class="category-slider" :slides-per-view="'auto'" :space-between="16" :allow-touch-move="true"
        :grab-cursor="true" @reach-end="onReachEnd" @slider-move="onSliderMove" :class="{ 'gradient': !isSliderEnd }">
        <swiper-slide v-for="category, number in orderCategories">
          <filter-category :id="category.id" :label="category.name" :name="'order-category'" :value="category.value"
            :checked="category.checked" @changed="onCategoryChange"></filter-category>
        </swiper-slide>
      </swiper>
    </div>
    <div class="orders-container">
      <p v-if="allOrdersCount == 0" class="orders-container__text">Заказов не найдено</p>
      <order-history-item v-for="order in orderHistory" :date="order.date" :number="order.number" :sum="order.sum"
        :status="order.status" :author="order.author"></order-history-item>
    </div>
    <v-pagination class="catalog-page__pagination" v-show="isPagination" :totalPages="getTotalPages"
      :currentPage="currentPage" @page-change="pageChange"></v-pagination>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue';
import InfoTooltip from '../components/InfoTooltip.vue';
import vRadio from '../components/v-radio.vue'
import vSelect from '../components/v-select.vue';
import FilterCategory from '../components/FilterCategory.vue';
import OrderHistoryItem from '../components/OrderHistoryItem.vue';
import vPagination from '../components/v-pagination.vue'
import vCalendar from '../components/v-calendar.vue';
import vLoading from '../components/v-loading.vue';

import { Swiper, SwiperSlide } from 'swiper/vue';

import { mapWritableState } from 'pinia'
import { useBreadcrumbs } from '../stores/breadcrumbs';

import { serverRequest } from '../api/request';

export default {
  name: 'OrderHistoryView',
  components: {
    CustomHeader,
    InfoTooltip,
    vRadio,
    vSelect,
    FilterCategory,
    OrderHistoryItem,
    vPagination,
    vCalendar,
    vLoading,
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      currentPage: 1,
      ordersOnPage: 10,
      allOrdersCount: 1,

      searched: false,

      isSliderEnd: false,

      isLoading: false,

      searchValue: '',
      currentCategory: 0,
      date: null,
      showedDate: null,

      orderCategories: [
        {
          id: 'cat-all',
          name: 'Все',
          value: '0',
          checked: true
        },
        {
          id: 'cat-cost',
          name: 'Расчеты стоимости',
          value: '1',
          checked: false
        },
        {
          id: 'cat-new',
          name: 'Новые заказы',
          value: '2',
          checked: false
        },
        {
          id: 'cat-active',
          name: 'Активные заказы',
          value: '3',
          checked: false
        },
        {
          id: 'cat-archive',
          name: 'Архив',
          value: '4',
          checked: false
        },
        {
          id: 'cat-request',
          name: 'Запросы',
          value: '5',
          checked: false
        },
      ],
      orderHistory: [
        // {
        //   date: '20/05/2020',
        //   number: 78086567,
        //   sum: 100000,
        //   status: 'Ожидает отгрузки',
        //   author: 'Константинов К. К.'
        // },
      ]
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'order-history',
        name: 'Мои заказы'
      },
    ]
    this.getOrdersHistory()
  },

  computed: {
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),

    isPagination() {
      if (this.allOrdersCount > this.ordersOnPage) {
        return true
      }
      else {
        return false
      }
    },

    getTotalPages() {
      if (this.isPagination) {
        return Math.ceil(this.allOrdersCount / this.ordersOnPage)
      }
      else {
        return 1
      }
    }
  },

  methods: {
    onCategoryChange(id, value) {
      this.currentCategory = parseInt(value)
      this.currentPage = 1

      this.getOrdersHistory()
    },

    pageChange(page) {
      this.currentPage = page

      this.getOrdersHistory()
    },

    onDateChange(date) {
      this.date = date;

      let start_date = (this.date[0].getDate()).toString().padStart(2, '0') + '.' + (parseInt(this.date[0].getMonth() + 1)).toString().padStart(2, '0') + '.' + this.date[0].getFullYear();
      let end_date = (this.date[1].getDate()).toString().padStart(2, '0') + '.' + (parseInt(this.date[1].getMonth() + 1)).toString().padStart(2, '0') + '.' + this.date[1].getFullYear();

      this.showedDate = start_date + ' - ' + end_date

      this.currentPage = 1

      this.getOrdersHistory()
    },

    onDateClear() {
      this.date = null;
      this.showedDate = null

      this.currentPage = 1

      this.getOrdersHistory()
    },

    searchOrders() {
      this.currentPage = 1
      this.searched = true

      this.getOrdersHistory()
    },

    blurHandler() {
      if (this.searchValue == '' && this.searched == true) {
        this.searched = false
        this.searchOrders()
      }
    },

    onReachEnd() {
      this.isSliderEnd = true
    },

    onSliderMove(slider) {
      if (slider.isEnd == false) {
        this.isSliderEnd = false
      }
    },


    async getOrdersHistory() {
      this.isLoading = true

      let formData = {
        search_value: this.searchValue,
        start_date: null,
        end_date: null,
        amount: this.ordersOnPage,
        type: this.currentCategory,
        start_pos: this.ordersOnPage * (this.currentPage - 1)
      }

      if (this.date != null) {
        formData.start_date = this.date[0].getDate() + '.' + (parseInt(this.date[0].getMonth() + 1)) + '.' + this.date[0].getFullYear();
        formData.end_date = this.date[1].getDate() + '.' + (parseInt(this.date[1].getMonth() + 1)) + '.' + this.date[1].getFullYear();
      }

      console.log(formData);

      let result = await serverRequest('POST', 'json', 'get_orders_history', formData)

      if (result != 'error' && result != 'unathorized') {
        this.allOrdersCount = result.all_orders_count
        this.orderHistory = result.orders
      }
      else {
        //alert('Ошибка запроса')
      }

      this.isLoading = false
    }
  }
}
</script>


<style lang="scss">
.order-history-page {
  @extend %white-bg-block;

  width: 100%;

  position: relative;

  &.loading {
    pointer-events: none;
  }

  &__header {
    display: flex;
    column-gap: 4px;

    .header-info {
      margin-top: 2px;
    }
  }

  .new-order-btn {
    margin-top: 32px;

    @extend %button;

    display: flex;
    column-gap: 12px;

    width: fit-content;
  }

  .filter-container {
    display: flex;
    align-items: center;
    column-gap: 32px;

    margin-top: 32px;
  }

  .search-input-container {
    position: relative;

    width: 100%;

    input {
      width: 100%;

      @extend %text-regular;
      color: var(--bw0);

      &::placeholder {
        @extend %text-regular;
        color: var(--bw3);
      }

      padding: 12px 44px 12px 16px;

      border-radius: 6px;
      border: 1px solid var(--bw4);

      background-color: var(--bw4);

      transition: border .3s;

      &:focus {
        border-color: var(--bw0);
      }
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      right: 16px;
    }
  }

  .order-category-block {
    margin-top: 32px;
  }

  .date-pick {
    cursor: pointer;

    min-width: 230px;

    display: flex;
    align-items: center;
    column-gap: 12px;

    width: fit-content;

    padding: 13px 16px;

    background-color: var(--bw4);

    border-radius: 4px;

    transition: background-color .3s;

    span {
      width: 100%;
      text-align: center;

      @extend %text-regular;
      color: var(--bw0);
      white-space: nowrap;
    }

    &:hover {
      background-color: var(--primary-bg);
    }
  }

  .orders-container {
    margin-top: 24px;

    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &__text {
      margin-top: 32px;
      @extend %H2;
    }
  }
}

@media screen and (max-width: 1600px) {
  .order-history-page {
    .search-input-container {
      input {
        padding: 11px 40px 11px 12px;
      }

      svg {
        right: 12px;
      }
    }

    .date-pick {
      min-width: 220px;

      padding: 10px 12px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .order-history-page {
    .search-input-container {
      input {
        padding: 12px 44px 12px 16px;
      }

      svg {
        right: 16px;
      }
    }

    .date-pick {
      min-width: 230px;

      padding: 13px 16px;
    }
  }
}



@media screen and (max-width: 600px) {
  .order-history-page {
    .filter-container {
      column-gap: 14px;
    }

    .date-pick {
      >span {
        display: none;
      }
    }
  }
}
</style>